import React from 'react'

// compositions
import Layout from '@compositions/Layout'
import PrivacyPolicy from '@compositions/PrivacyPolicy'

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <PrivacyPolicy />
    </Layout>
  )
}

export default PrivacyPolicyPage
