import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'

// components
import SEO from '@components/SEO'
import Hero from './components/Hero'

import { privacyContent } from './modules/'

const PrivacyPolicy = styled(({ className }) => {
  const privacyPageElement = privacyContent()[0]
  const privacySeo = privacyContent()[0][0]
  const privacyHero = privacyContent()[0][1]

  return (
    <div className={`${className}`}>
      <SEO title={privacySeo.title} description={privacySeo.description} />
      <div className="bg-alabaster">
        <section className="d-inline-block w-100">
          <Hero data={privacyHero} />
        </section>
      </div>
      <section className="privacy-detail">
        <div className="container">
          <div className="privacy-detail-inner">
            {privacyPageElement.map((section, key) => {
              const { __typename: typename } = section || {}
              switch (typename) {
                case 'WPGraphQL_Page_Allpageelements_Pageelements_Contentblockdata':
                  return (
                    <div key={key}>
                      {section.contentList.map((item, key) => (
                        <div className="item" key={key}>
                          { item.title && (
                            <span className="h5">{item.title}</span>
                          )}
                          {item.ptext.map((text, key) => (
                            <p key={key}>{text.paragraf}</p>
                          ))}
                        </div>
                      ))}
                    </div>
                  )
                case 'WPGraphQL_Page_Allpageelements_Pageelements_Listblockdata':
                  return (
                    <div key={key} className="list-block">
                      <span className="h5">{section.title}</span>
                      {section.listContent.map((section, key) => {
                        const { __typename: typename } = section || {}
                        switch (typename) {
                          case 'WPGraphQL_Page_Allpageelements_Pageelements_Listblockdata_ListContent_List':
                            return (
                              <ul key={key}>
                                {section.list.map((list, key) => (
                                  <li key={key}>
                                    <span className="h6">{list.title}</span>
                                    <p>{list.content}</p>
                                  </li>
                                ))}
                              </ul>
                            )
                          case 'WPGraphQL_Page_Allpageelements_Pageelements_Listblockdata_ListContent_Description':
                            return (
                              <div className="description" key={key}>
                                {section.description}
                              </div>
                            )
                          default:
                            return false
                        }
                      })}
                    </div>
                  )
                case 'WPGraphQL_Page_Allpageelements_Pageelements_Typeblockdata':
                  return (
                    <div className="types" key={key}>
                      {section.types.map((section, key) => {
                        const { __typename: typename } = section || {}
                        switch (typename) {
                          case 'WPGraphQL_Page_Allpageelements_Pageelements_Typeblockdata_Types_Title':
                            return (
                              <span className="types-title" key={key}>{section.title}</span>
                            )
                          case 'WPGraphQL_Page_Allpageelements_Pageelements_Typeblockdata_Types_List':
                            return (
                              <div className="type-box" key={key}>
                                <span className="h5">Type of privacy : <b>{section.title}</b></span>
                                {section.list.map((list, key) => {
                                  let islink = list.islink ? list.islink : false
                                  return (
                                    <div className="type-box-list" key={key}>
                                      <span>{list.title}:</span>
                                      { islink === true && (
                                        <a href={list.description}>{list.description}</a>
                                      )}
                                      { islink === false && (
                                        <div>{list.description}</div>
                                      )}
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          case 'WPGraphQL_Page_Allpageelements_Pageelements_Typeblockdata_Types_Description':
                            return (
                              <div
                                className="types-description"
                                dangerouslySetInnerHTML={{
                                  __html: section.description
                                }}
                                key={key}
                              />
                            )
                          default:
                            return false
                        }
                      })}
                    </div>
                  )
                default:
                  return false
              }
            })}
          </div>
        </div>
      </section>
    </div>
  )
})`
  .privacy-detail {
    //background-color: #fafafa;
    position: relative;

    .privacy-detail-inner {
      padding: 88px 7px 60px 8px;

      @media (min-width: 768px) {
        padding: 90px 0 60px;
      }

      @media (min-width: 1440px) {
        padding: 80px 95px 6px;
      }

      .item {
        padding-bottom: 47px;

        .h5 {
          color: #000000;
          display: block;
          font-family: Roboto;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 16px;
        }

        p {
          font-family: Roboto;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: 0.8px;
          color: #8e8e8e;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .list-block {
        margin-bottom: 47px;

        .h5 {
          color: #000000;
          display: block;
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 0.03em;
          line-height: 32px;
          margin-bottom: 20px;
        }

        .description {
          color: #767676;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 26px;
        }

        > *:last-child {
          margin-bottom: 0;
        }

        ul {
          padding: 0;
          margin: 0 0 24px;

          li {
            list-style-type: none;
            padding-left: 14px;
            position: relative;
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }

            &:before {
              content: '';
              background-color: #2b58ca;
              border-radius: 50%;
              height: 6px;
              position: absolute;
              left: 0;
              top: 9px;
              width: 6px;
            }

            .h6 {
              color: #000000;
              display: block;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0.03em;
              line-height: 24px;
              margin-bottom: 8px;
            }

            p {
              color: #767676;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 0;
            }
          }
        }
      }

      .types {

        .types-title{
          color: #000000;
          display: block;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px;
        }

        .types-description{
          margin-bottom: 32px;

          p{
            color: #767676;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.03em;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .type-box {
          margin-bottom: 32px;

          .h5{
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 8px;
          }

          .type-box-list {
            margin-bottom: 8px;

            span{
              color: #000;
              font-size: 16px;
              letter-spacing: 0.03em;
              line-height: 24px;
              margin-right: 6px;
            }

            div{
              color: #767676;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.03em;
            }

            span, div{
              display: inline-block;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
`

PrivacyPolicy.propTypes = {
  data: object.isRequired
}

PrivacyPolicy.defaultProps = {
  data: {}
}

export default PrivacyPolicy
