import React from 'react'
import { string } from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Hr = styled(({ color, small, className }) => {
  return (
    <hr
      className={className}
      css={css`
        background-color: ${color};
        width: ${small ? '50px' : '100px'};
      `}
    />
  )
})`
  border: 0;
  height: 2px;
  margin: 0;
`

Hr.propTypes = {
  color: string,
  small: string
}

Hr.defaultProps = {
  color: '#6dc072',
  small: null
}

export default Hr
